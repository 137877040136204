@import '../variables.scss';

/// ### MAX WIDTH ###
@mixin maxwidths-selector {
    @each $name, $perc in $percentages {
        &-#{"" + $name} {
            max-width: $perc!important;
        }
    }
}

.mw {
    @include maxwidths-selector();
}

/// ### LINE HEIGHT ###
@mixin lineheights-selector {
    @each $name, $linhgt in $numbers {
        &-#{"" + $name} {
            line-height: $linhgt!important;
        }
    }
}

.lh {
    @include lineheights-selector();
}

/// ### MARGINS ###
@mixin margintop-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            margin-top: $m!important;
        }
    }
}

.mt {
    @include margintop-selector();
}

@mixin marginbottom-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            margin-bottom: $m!important;
        }
    }
}

.mb {
    @include marginbottom-selector();
}

@mixin marginleft-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            margin-left: $m!important;
        }
    }
}

.ml {
    @include marginleft-selector();
}

@mixin marginright-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            margin-right: $m!important;
        }
    }
}

.mr {
    @include marginright-selector();
}

/// ### PADDINGS ###
@mixin paddingtop-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            padding-top: $m!important;
        }
    }
}

.pt {
    @include paddingtop-selector();
}

@mixin paddingbottom-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            padding-bottom: $m!important;
        }
    }
}

.pb {
    @include paddingbottom-selector();
}

@mixin paddingleft-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            padding-left: $m!important;
        }
    }
}

.pl {
    @include paddingleft-selector();
}

@mixin paddingright-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            padding-right: $m!important;
        }
    }
}

.pr {
    @include paddingright-selector();
}

@mixin heights-selector {
    @each $name, $m in $pixels {
        &-#{"" + $name} {
            height: $m!important;
        }
    }
}

.h {
    @include heights-selector();
}