.dps_container_and_wrapper {
    min-height: 100%;
    /* Equal to height of footer */
    /* But also accounting for potential margin-bottom of last child */
    margin-bottom: -50px;
    .dps_header {
        .navbar {
            // flex-direction: column;
            // float: right;
            .pre-banda {
                width: -webkit-fill-available;
                /* padding: 0px !important; */
                margin: 0px 10px;
                .banda {
                    height: auto;
                    background-color: #27aab4;
                    .banda_row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        span {
                            color: white;
                            font-size: 20px;
                            text-transform: uppercase;
                            font-weight: bold;
                            margin-left: 25px;
                            padding: 2px;
                        }
                    }
                    .banda_row_user {
                        .banda_row_user_left {
                            float: left;
                            span {
                                color: white;
                                font-size: 20px;
                                text-transform: uppercase;
                                font-weight: bold;
                                margin-left: 25px;
                                padding: 2px;
                            }
                        }
                        .banda_row_user_right {
                            display: flex;
                            flex-direction: row;
                            float: right;
                            padding-right: 10px;
                            span {
                                color: white;
                                font-size: 14px;
                                text-transform: uppercase;
                                font-weight: bold;
                                margin-left: 5px;
                                padding: 2px;
                                transition: 400ms ease-in-out;
                            }
                            span:hover{

                                color: rgb(255, 150, 111);
                            }
                        }
                    }
                }
            }
        }
    }
    .dps_body {
        margin: 0px 25px;
        padding-bottom: 25px;
        .container-fluid {
            color: inherit;
            .dps_bodyhome_title {
                font-size: 25px;
                text-transform: uppercase;
                font-weight: bold;
                color: #27aab4;
                margin-bottom: 30px;
            }
        }
    }
    .dps_home_events_container {
        .dps_home_events_item {
            a {
                color: black;
                &:hover {
                    p {
                        text-decoration: underline;
                    }
                }
            }
            padding-bottom: 10px;
            margin-bottom: 25px;
            // border-bottom: 1px solid lightgray;
            .dps_home_event_namedesc {
                .title {
                    font-size: 20px;
                    font-weight: bold;
                }
                .desc {
                    font-size: 15px;
                    i {
                        color: #27aab4;
                    }
                }
            }
        }
    }
    .dps_home_events_container_editable {
        display: flex;
        background-color: #f7f7f7;
        padding: 25px;
        flex-direction: column;
        .dps_home_events_item {
            a {
                color: black;
                &:hover {
                    p {
                        text-decoration: underline;
                    }
                }
            }
            padding-bottom: 10px;
            margin-bottom: 25px;
            // border-bottom: 1px solid lightgray;
            .dps_home_event_namedesc {
                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #01354d;
                }
                .desc {
                    font-size: 18px;
                    color: #4a818f;
                    i {
                        color: #27aab4;
                    }
                }
            }
        }
    }
    .dps_body_registration {
        margin: 0px 25px;
        padding-bottom: 125px;
        .dps_guest_reg_items {
            span {
                text-transform: uppercase;
                font-weight: bold;
                padding: 5px 0px;
            }
        }
    }
    .dps_body_crea_evento {
        .dps_creaevento_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_gestisci_evento {
        .dps_gestevento_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_prenotazione_evento {
        .dps_prenotavento_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_accettazione_eventi {
        .dps_accettazioneeventi_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_accettazione_utenteevento {
        .dps_accettazione_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
        .qr_reader_options { 
            
        }
        .qr_reader_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            & section {
                width: 300px!important;
            }
        }
    }
    .dps_body_report_evento {
        .dps_reportevento_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_report_attivita {
        .dps_reportattivita_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_ass_esterne {
        .dps_assesterne_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_crea_associazione {
        .dps_creaassociazione_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_manage_account {
        .dps_manageaccount_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
        }
        .manageaccount_infobox {
            .manageaccount_infobox_buttons {
                display: flex;
                flex-direction: column;
            }
        }
    }
    .dps_body_reset_password {
        .dps_resetpassword_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
    }
    .dps_body_streaming_page {
        background: url('https://cndcecpsstorage.blob.core.windows.net/static-files/images/Comp-per-skin-FNC-newer.jpg') top repeat-y;
        min-height: 1000px;
        .dps_streamingpage_title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            color: #27aab4;
            margin-bottom: 30px;
            margin-top: 50px;
        }
        .dps_streamingpage_player {
            padding-top: 100px;
        }
    }
}

// out of main.js footer
.dps_footer {
    // STEALED FROM CNDCEC
    .rm-alignToCenterInColumn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
    }
    .rm-alignToCenterInRowspacedBeetwen {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .rm-alignToCenterInRowspacedBeetwen {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .rm-alignToCenterInRow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
    }
    #colonnaFooter2 {
        text-align: left;
    }
    @media only screen and (max-width:720px){
        #colonnaFooter2{
            text-align:left
        }
    }
    #colonnaFooter3{
        text-align: right;
    }
    @media only screen and (max-width:720px){
        #colonnaFooter3{
            text-align:left
        }
    }
    // END OF STEALED CODE
    .pb_directio {
        a {
            color: black;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .info_footer {
        margin: 1px;
        align-items: left;
        float: left;
        padding: 1px;
        color: white;
        text-align: left;
        font-size: 12px;
    }    
    .info_iso {
        margin: 1px;
        padding: 1px;
        color: white;
        font-size: 14px;
    }
    .logodps_footer {
        align-content: center;
        margin-left: 10px;
    }
    .grid_footer {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 33% 33% 33%;
        grid-gap: 1px;
        padding: 5px;
        background-color:#27aab4;
    }
    .grid_footer > div {
        vertical-align: middle;
        text-align: center;
        padding: 5px 0;
        font-size: 14px;
    }
    .item1_footer {
        grid-row: 1 / span 1;
        margin: auto;
    }
    .item2_footer {
        grid-row: 1 / span 0;
        margin: auto;
    }
    .item3_footer {
        grid-row: 1 / span 0;
        margin: auto;
    }    
    .grid_inner-footer {
        margin-top: 3px;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 50% 50%;
        grid-gap: 1px;
        padding: 5px;   
    }
    .grid_inner-footer > div {      
        text-align: center;
        padding: 5px 0;
        font-size: 20px;
    }
    .item_contact {
        grid-row: 1 / span 1;
    }
    .item_dati {
        grid-row: 1 / span 1;
    }
}


// MENU


.navbar-light .navbar-brand {
    color: rgb(255 255 255 / 100%);
}
.navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.1rem;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;
}



body {margin: 0;}

ul.sidenav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 25%;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
  }
  
  ul.sidenav li a {
    display: block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
  }
   
  ul.sidenav li a.active {
    background-color: #4CAF50;
    color: white;
  }
  
  ul.sidenav li a:hover:not(.active) {
    background-color: rgb(33, 170, 224);
    color: white!important;
  }
  
  div.content {
    margin-left: 25%;
    padding: 1px 16px;
    height: 1000px;
  }
  
  @media screen and (max-width: 2800px) {
    ul.sidenav {
      width: 100%;
      height: auto;
      position: relative;
    }
    
    ul.sidenav li a {
      float: left;
      padding: 15px;
    }
    
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 600px) {
    ul.sidenav li a {
      text-align: center;
      float: none;
    }
  }