.tg  {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
.tg td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 15px 15px;
    word-break: normal;
}
.tg th {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 15px 15px;
    word-break: normal;
}
.tg .tg-0lax {
    text-align: left;
    vertical-align: top
}
.tg tr.tg-tr-t0t4 {
    background-color: #D3D3D3;
}