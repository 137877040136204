.eventList {
    margin-top: $space-medium;
    margin-bottom: $space-medium;
    display: flex;
    flex-direction: column;
    .box-event {

        margin-bottom: 10px;
        .box-title {

            min-height: 130px;
            padding: 0.625rem;
            width: 100%;
            background-color: $Background_Box;
            color: $Color_Site;
            font-size: 12px;
            .box-data{
                font-size: 15px;
                margin-bottom: 0.2rem;
            }
            .title-event {
                color: $Background_Header;
                font-size: 15px;
                margin-bottom: 0.2rem;
            }
            .code-event {
                font-size: 12px;
            }
            .buttons-event {
                margin-top: $base-spacing;
                margin-bottom: $base-spacing;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                color: $Color_Link;
                font-size: 12px;
            }
        }
    }
    .box-detail {
        &.credit {
            font-size: 12px;
        }
        background-color: $Background_Header2;
        padding: 0.625rem;
        color: $Color_Site;
        .box-materie {
            border-bottom: 1px solid $color-white;
            .box-materie-detail{  padding-bottom: 5px; }
        }
        .detail {
            border-bottom: 1px solid $color-white;
            margin-top: 20px;
            i {
                color: $Color_Link;
                padding-right: 5px;
               
            }
            .data-detail {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-bottom: 5px;
                span {
                    padding-right: 20px;
                }
            }
            .event-old-text {
                color: $color-gray;
            }
        }
        .detail:last-child{
            border-bottom: none;
        }

    }
}

// .eventList>* {
//     flex: 1 1 auto;
// }

.select-year{
    margin-right: 10px;
}

.box-data-total{
   margin-left: 20px;
   font-weight: 700;
}

.box-matter {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .box-matter-credit span {
        margin-left: 10px;
    }
}

.eventCreditList {
    margin-top: $space-medium;
    margin-bottom: $space-medium;
    display: flex;
    flex-direction: column;
    .box-event-credit {
        margin-bottom: 10px;
        border-bottom: 1px solid $Border-color;
        .box-title {
            padding: 0.625rem;
            padding-left: 0;
            width: 100%;
            color: $Color_Site;
            font-size: 12px;
            .box-data{
                font-size: 15px;
                margin-bottom: 0.2rem;
            }
            .title-event {
                color: $Background_Header;
                font-size: 15px;
                margin-bottom: 0.2rem;
            }

            .buttons-event-credit {
                margin-top: $space-medium;
                margin-bottom: $space-medium;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                color: $Color_Link;
                font-size: 12px;
                span {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    // i.fmi-12 {
                    //     font-size: 12px !important;
                    // }
                    // i.fmi-20 {
                    //     font-size: 20px !important;
                    // }
                    // i.fmi-22 {
                    //     font-size: 22px!important;
                    // }
                    // i.fmi-28 {
                    //     font-size: 28px!important;
                    // }
                  
                }

            }
        }
    }

    .box-detail-credit{
        padding-left: .625rem;
        padding-bottom: .625rem;
        padding-right: .625rem;
        color: $Color_Site;
        display: flex;
        flex-direction: column;
        .box-detail-credit-data {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            i {
                color: $Color_Link;
                padding-right: 5px;
               
            }
            .box-data {
                display: flex;
                flex-direction: row;
                padding-bottom: 5px;
                span {
                    padding-right: 20px;
                    padding-left: 5px;
                    font-weight: bold;
                }
            }
            .box-matter {
                margin-left: 10px;
                font-weight: bold;
            }
        }
    }
}

.statusActive {
    color: $Background_Header;
    font-size: 15px;
    font-weight: bold;
}

.statusWaiting {
    color: $Background_Box;
    font-size: 15px;
    font-weight: bold;
}


@media screen and (break(medium)) {
    .eventList {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
      
        .box-event {
            width: 32%;
            margin-right: 10px;
        }
    
    }
    .eventCreditList{
        .box-detail-credit{
            .box-detail-credit-data{
            flex-direction: row;
            }
        }
    }
}