.dps_login_box {
    background-color: #01354D;
    color: white;
    padding: 25px;
    .dps_login_box_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .reset_box_container {
            display: block;
            &.hidden {
                display: none;
            }
        }
    }
}


.title_login_bar{    text-transform: uppercase;
    background-color: #27aab4;
    color: #ffffff;
    padding: 5px 10px;
    margin-bottom: 0px;}

.title_events_bar{
    text-transform: uppercase;
    background-color: #3c7787;
    color: white;
    padding: 5px;
    margin-bottom: 0px;
}

// RADIO BUTTON ANT

.ant-radio-checked .ant-radio-inner {
    border-color: #01354D;
}

.ant-radio-inner::after {

    background-color: #01354D;
}


// MENU


.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    filter: invert(1);
}


.navbar-toggler-icon:hover {
    filter: drop-shadow(2px 4px 6px #3f567a);
}

.navbar-light .navbar-toggler {
    color: transparent;
    border-color: transparent;
}

.bg-primary {
    background-color: transparent !important;
}

.navbar-expand-lg .navbar-collapse {
    margin-top: 10px;
}

.left_area_title{
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: -25px;
}

.info_right{

    float: right;
    margin-top: 5px;
    margin-right: 10px;
}

.menu_style{

    a{
        color: white;
    }

}



//padding left gutter row ovveride


.plg_0 
{
    padding-left: calc(var(--bs-gutter-x)*.0);
}


.ant-btn:hover, .ant-btn:focus {
    // color: #40a9ff;
    // background: #fff;
    border-color: #ffffff;
}


.pass_reset{

    margin-top: 20px;

a:not([href]):not([class]){
     color: #ffffff !important;
     transition: ease-in-out 400ms;
}
a:not([href]):not([class]):hover {

    color: #27AAB4 !important;
}

}
