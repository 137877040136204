.btn-booking {
    background-color: $Color_Link;
    color: $color-white;
    padding: 0.2rem;
    font-size: 0.8125rem;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    &:hover{
        background-color:$color-white;
        color: $Color_Link;
        border: 1px solid $Color_Link;
    }
}

.btn-save {
    text-transform: uppercase;
    background-color: $Color_Link;
    color: $color-white !important;
    padding: 0.2rem;
    font-size: 0.8125rem;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-left:  0.625rem;
    &:hover, &:active, &:focus{
        background-color:$color-white;
        color: $Color_Link!important;
        border: 1px solid $Color_Link;
    }
}

.box-button{
    margin-top: 10px;
    display: flex;
    .btn-save{
        margin-right: 20px;
        margin-left:0;

    }
    a{
        &.btn-booking, span {
            margin-right: 20px;
            margin-left:0;
            display: flex;
            align-items: center;
            i {
                font-size: 20px;
                padding-right: 5px;
            }
        }
        span {
            margin-right: 0px;
        }
    }
}

.button-text {
    margin-top: 30px;
}

.btn-default {
    background-color: $color-white;
    color: $Color_Link;
    border: 1px solid $Color_Link;
    display: flex;
    align-items: center;
    padding: 0.375rem .75rem !important;
    font-size: 0.875rem;
    text-transform: uppercase;
    min-height: 40px;
    margin-left: 0.9375rem;
    margin-top: -2.8125rem;
    &:hover {
        background-color: $Color_Link;
        color: $color-white !important;
        i {
            font-size: 16px;
            color: $color-white;
        }
    }
    i {
        font-size: 16px;
        color: $Color_Link;
    }
}
.box-data-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.bordered {
        border-bottom: 1px solid $Border-color;
        margin-bottom: 20px;
    }
    a{
        &.btn-title {
            background-color: $color-white;
            color: $Color_Link;
            border: 1px solid $Color_Link;
            &:hover {
                background-color: $Color_Link;
                color: $color-white;
            }
            
        }
    }
}