@import "../../node_modules/bootstrap/scss/bootstrap.scss"; // <-- BOOTSTRAP VERSION 5
@import "../../node_modules/antd/dist/antd";
@import "../../node_modules/rfs/scss.scss";
// @import "../../node_modules/react-toastify/dist/ReactToastify.css";
@import "../../node_modules/react-quill/dist/quill.snow.css";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

///// Core dependencies
//VARIABLES
@import "./core/responsive-settings.scss";
@import "./core/normalize.scss";
@import "./core/ui-options.scss";

@import "./core/commons.scss";
@import "./core/variables.scss";

// Components
@import "./core/content/**.scss";
@import "./core/components/**.scss";
@import "./core/utility/**.scss";

// Font official
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

html,
body {
    height: 100%;
    /* The html and body elements cannot have any padding or margin. */
    // font-family: $font-main;
    font-family: 'Titillium Web', sans-serif;
    overflow-x: hidden;
    margin: 0;
}

.wrapper {
    min-height: 100%;

    /* Equal to height of footer */
    /* But also accounting for potential margin-bottom of last child */
    margin-bottom: -50px;
}

#logoHeader {
    max-width: 290px;
    height: 52px;
}

.button-invia {
    border: none;
    color: white;
    padding: 7px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: #16195e;
    height: 34px;
    float: right;
}

.iconAdress {
    height: 22px;
    width: 24px;
}

.card-title {
    color: #16195e;
    font-weight: bold;
}

p {
    text-align: left;
    line-height: 16px;
    color: black;
    font-size: 12px;
}

.row.header {
    margin-right: calc(var(--bs-gutter-x) * -2.5);
    margin-left: calc(var(--bs-gutter-x) * -1.5);
}

.navbar-toggler:not([disabled]):not(.disabled) {
    color: #16195e !important;
    background-color: #16195e !important;
    border-radius: 0;
}

.navbar-light .navbar-toggler-icon {
    height: 24px;
    width: 24px;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem;
}

#textHeaderNavbar {
    color: #16195e;
    font-size: 13px;
}

/*navbar*/
@media (max-width:574px) {

    .navbar-collapse {

            // display: flex;
            height: 30vh;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: #16195ebd;
            margin-top: 4px;

            #textHeaderNavbar {
                color: white;
                font-size: 13px;
            }

        ul {
            // display: flex;
            flex-direction: column;
            align-items: start;
            list-style-type: none;


            li {
                padding: 6px 0;
                margin-left: 50%;

                a {
                    position: relative;
                    display: block;
                    padding: 4px 0;
                    font-family: Lato, sans-serif;
                    color: #ecf0f1;
                    text-decoration: none;
                    text-transform: uppercase;
                    transition: 0.5s;

                    &::after {
                        position: absolute;
                        content: "";
                        top: 100%;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background: #3498db;
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    &:hover {
                        color: #95a5a6;
                    }

                    &:hover::after {
                        transform: scaleX(1);
                        transform-origin: left;
                    }
                }
            }
        }

        .navbar-collapse ul li {
            padding: 8px 0;
            margin-left: 50%;
        }
    }
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 5px;
    height: 5px;
    padding: 0;
    margin-right: 4px;
    margin-left: 4px;
    text-indent: -999px;
    cursor: pointer;
    background-color: white;
    background-clip: padding-box;
    border-radius: 100%;
    border: 1.8px solid #16195e;
}

.carousel-indicators .active {
    background-color: #16195e;
}

.carousel-control-prev {
    bottom: 74px;
}

.carousel-control-next {
    bottom: 74px;
}

#map {
    width: 653px;
    height: 253px;
}

@media only screen and (max-width: 1036px) {

    [class*="col-sm"] {
        width: 50%;
    }
}

@media only screen and (max-width: 354px) {

    [class*="col-sm"] {
        width: 100%;
    }
}

@media only screen and (max-width: 375px) {

    [class*="col-sm"] {
        width: 100%;
    }
}

@media only screen and (min-width: 1400px) {

    [class*="col-xxl"] {
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {

    [class*="col-xxl"] {
        width: 100%;
        height: 194px;
    }
}

@media only screen and (min-width: 1400px) {

    #imgHeader {
        width: 103%;
        margin-left: -13px;
        height: auto;
    }
}

@media only screen and (min-width: 1600px) {

    #map {
        width: 100%;
        height: 322px;
    }
}

@media only screen and (max-width: 1400px) {

    #imgHeader {
        width: 103%;
        margin-left: -13px;
        height: auto;
    }
}

@media only screen and (max-width: 1040px) {

    #imgHeader {
        width: 106%;
        margin-left: -13px;
        height: 209px;
    }
}

@media only screen and (max-width: 400px) {

    [class*="navbar-brand"] {
        margin-left: 0px !important;
    }

    #footerContact {
        margin-left: -57px !important;
        margin-top: 15px;
    }

    #imgHeader {
        height: 171px;
        width: 110%;
    }

    #footer {
        margin-top: 7px !important;
    }
}

/*Modal carousel*/
#myImg {

    cursor: pointer;
    transition: 0.3s;
}

#myImg2:hover {
    opacity: 0.9;
}

.modal {

    display: none;
    position: fixed;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {

    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    margin-top: 129px;
}

#caption {

    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

.modal-content,
#caption {

    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {

    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {

    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.close {

    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {

    color: red;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {

    .modal-content {
        width: 100%;
    }
}

@media only screen and (max-width: 1400px) {

    .modal-content {
        width: 100%;
    }
}

.footer,
.push {

    height: 225px;
    background-color: #16195e;
}

h1 {

    color: $Color_Title;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 1.5em;
}

a {

    color: $Color_Link;

    &:hover {
        color: $Color_Title;
    }
}

.ant-tabs-nav {
    .ant-tabs-tab:hover {
        color: $Color_Link;
    }

    .ant-tabs-tab-active {
        color: $Color_Link;

    }
}

.ant-tabs-ink-bar {

    background-color: $Color_Link;
}

.pagination {

    justify-content: center;

    .page-item.active .page-link {
        z-index: 3;
        color: #fff;
        background-color: $Color_Link;
        border-color: $Color_Link;
    }
}

// .body-content {
//    // min-height: 65%;
// }
/* Wrapper for page content to push down footer */
#wrap {
    min-height: 100%;
    height: auto;
    /* Negative indent footer by its height */
    margin: 0 auto -30px;
    /* Pad bottom by footer height */
    padding: 0 0 30px;
}

.jumbotron {
    min-height: 300px;
}

// trying to hide blue borders generate by browsers
:focus {
    outline: none !important;
}

.form-errors-paragraph {
    font-size: 11px;
    color: #d62128;
    font-weight: 300;
    text-transform: uppercase;
    transition: ease-in 400ms;
    padding: 5px;
    /* display: none; */
}

.dps_back_row {
    margin-top: 250px;
}

.radio_column {
    display: flex;
    flex-direction: column;
}


// styled by Lazar



.navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255);
    font-size: 16px;
}

.ant-btn {

    font-weight: 700;
    color: #01354D;
    text-transform: uppercase;
    transform: 400ms ease-in;
}

.ant-btn:hover {
    background-color: #01354D;
    color: white;

    a {

        color: white !important;
    }
}

.label_title {

    color: #01354D;
    font-size: 22px;
    font-weight: 600;
}

.label_white {

    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
}

.box_ativita {
    background: aliceblue;
    padding: 25px;
    border: 1px solid #27aab4;
}


.ativita_create_title {
    font-size: 18px;
    text-transform: uppercase;
    color: #27aab4;
    margin-top: 15px;

    a {
        color: #27aab4;
    }
}

.ativita_create {
    padding: 5px;
    margin-bottom: 10px;
    /* border-bottom: 1px solid lightgrey; */
    /* border-top: 1px solid lightgrey; */
    background: aliceblue;
    font-size: 18px;
    margin-top: 10px;
}

.section_divider:not([size]) {
    height: 4px;
    color: #27aab4;
    opacity: 1;
}

.gestione_evento {

    font-size: 20px;
}

.prenatozione_evento {
    border: 1px solid #27aab4;
    padding: 5px 30px;
    border-radius: 3px;
    background: aliceblue;
    font-size: 16px;
}

.alert-info {
    color: #ff851b;
    background-color: transparent;
    border-color: transparent;
    font-size: larger;
}