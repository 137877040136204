$Background_Header: #2C436D;
$Background_Header2: #DDE2E5;

$Background_Box: #F1F5F6;
$Color_Site: #000000;
$Color_SiteHeader: #ffffff;
$Color_Link: #3e6aba;
$Color_Title: #2c436d;
$Border-color: #DDE2E5;
$Color-Tab:#3e6aba;

$colors: (
    black: rgb(0, 0, 0),
    white: rgb(255, 255, 255),
    gray: rgb(128, 128, 128),
    darkgray: rgb(169, 169, 169),
    lightgray: rgb(211, 211, 211),
    blue: rgb(0, 0, 255),
    red: rgb(255, 0, 0),
    green: rgb(0, 128, 0),
    yellow: rgb(255, 255, 0),
    orange: rgb(255, 165, 0),
);

$percentages: (
    0:  0%,
    1:  1%,
    2:  2%,
    3:  3%,
    4:  4%,
    5:  5%,
    6:  6%,
    7:  7%,
    8:  8%,
    9:  9%,
    10: 10%,
    11: 11%,
    12: 12%,
    13: 13%,
    14: 14%,
    15: 15%,
    16: 16%,
    17: 17%,
    18: 18%,
    19: 19%,
    20: 20%,
    21: 21%,
    22: 22%,
    23: 23%,
    24: 24%,
    25: 25%,
    26: 26%,
    27: 27%,
    28: 28%,
    29: 29%,
    30: 30%,
    31: 31%,
    32: 32%,
    33: 33%,
    34: 34%,
    35: 35%,
    36: 36%,
    37: 37%,
    38: 38%,
    39: 39%,
    40: 40%,
    41: 41%,
    42: 42%,
    43: 43%,
    44: 44%,
    45: 45%,
    46: 46%,
    47: 47%,
    48: 48%,
    49: 49%,
    50: 50%,
    51: 51%,
    52: 52%,
    53: 53%,
    54: 54%,
    55: 55%,
    56: 56%,
    57: 57%,
    58: 58%,
    59: 59%,
    60: 60%,
    61: 61%,
    62: 62%,
    63: 63%,
    64: 64%,
    65: 65%,
    66: 66%,
    67: 67%,
    68: 68%,
    69: 69%,
    70: 70%,
    71: 71%,
    72: 72%,
    73: 73%,
    74: 74%,
    75: 75%,
    76: 76%,
    77: 77%,
    78: 78%,
    79: 79%,
    80: 80%,
    81: 81%,
    82: 82%,
    83: 83%,
    84: 84%,
    85: 85%,
    86: 86%,
    87: 87%,
    88: 88%,
    89: 89%,
    90: 90%,
    91: 91%,
    92: 92%,
    93: 93%,
    94: 94%,
    95: 95%,
    96: 96%,
    97: 97%,
    98: 98%,
    99: 99%,
    100: 100%,
);

$numbers: (
    0: 0,
    0-5: 0.5,
    1: 1,
    1-5: 1.5,
    2: 2,
    2-5: 2.5,
    3: 3,
    3-5: 3.5,
    4: 4,
    4-5: 4.5,
    5: 5,
);

$pixels: (
    0:  0px,
    1:  1px,
    2:  2px,
    3:  3px,
    4:  4px,
    5:  5px,
    6:  6px,
    7:  7px,
    8:  8px,
    9:  9px,
    10: 10px,
    11: 11px,
    12: 12px,
    13: 13px,
    14: 14px,
    15: 15px,
    16: 16px,
    17: 17px,
    18: 18px,
    19: 19px,
    20: 20px,
    21: 21px,
    22: 22px,
    23: 23px,
    24: 24px,
    25: 25px,
    26: 26px,
    27: 27px,
    28: 28px,
    29: 29px,
    30: 30px,
    31: 31px,
    32: 32px,
    33: 33px,
    34: 34px,
    35: 35px,
    36: 36px,
    37: 37px,
    38: 38px,
    39: 39px,
    40: 40px,
    41: 41px,
    42: 42px,
    43: 43px,
    44: 44px,
    45: 45px,
    46: 46px,
    47: 47px,
    48: 48px,
    49: 49px,
    50: 50px,
    51: 51px,
    52: 52px,
    53: 53px,
    54: 54px,
    55: 55px,
    56: 56px,
    57: 57px,
    58: 58px,
    59: 59px,
    60: 60px,
    61: 61px,
    62: 62px,
    63: 63px,
    64: 64px,
    65: 65px,
    66: 66px,
    67: 67px,
    68: 68px,
    69: 69px,
    70: 70px,
    71: 71px,
    72: 72px,
    73: 73px,
    74: 74px,
    75: 75px,
    76: 76px,
    77: 77px,
    78: 78px,
    79: 79px,
    80: 80px,
    81: 81px,
    82: 82px,
    83: 83px,
    84: 84px,
    85: 85px,
    86: 86px,
    87: 87px,
    88: 88px,
    89: 89px,
    90: 90px,
    91: 91px,
    92: 92px,
    93: 93px,
    94: 94px,
    95: 95px,
    96: 96px,
    97: 97px,
    98: 98px,
    99: 99px,
    100: 100px,
);
