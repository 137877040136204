.breadcrumb {
    background-color: $color_white;
    font-size: 12px;
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid $Color_Site;
    color: $Color_Title;
    border-radius: 0;
    
    li:first-child {
        a {
            cursor: not-allowed;
        }
    }
}

@media screen and (break(medium)) {
    .breadcrumb {
        font-size: 13px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
}