.ant-pagination-item-active a {
    color: $Color_Link;
}

.ant-pagination-item-active {
    border-color: $Color_Link;
}

.ant-table-pagination.ant-pagination {
    float: none;
    margin: 16px 0;
    text-align: center;
    bottom: 0;
}

.ant-table-thead>tr>th {
    background-color: $Background_Box;

}