.title-box {
    font-weight: bold;
    font-size: 15px;
    padding-right: 10px;
    margin-bottom: 10px;
    width: 20%;
    text-align: right;
}

.box-data-insert {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;
    margin-bottom: 15px;
    &.box-border-bottom{
        padding-bottom: $space-medium;
        border-bottom: 1px solid $Border-color;
    }
    .ant-input{
        width: 80%;
        margin-right: 10px;
        margin-left: 10px;
    }
    .ant-input-number, .ant-select {
        margin-right: 10px;
        margin-left: 10px;
    }
    .ant-btn{
        color: $Color_Link;
    }
    .ant-input-disabled, .ant-input-number-disabled {
        background: $color_white;
        border: none;
        color: $Color_Site;
    }
    .btn-save {
        background: $Color_Link;
        color: $color_white;
    }

    .ant-btn-disabled, .ant-btn-disabled.active, 
    .ant-btn-disabled:active, .ant-btn-disabled:focus,
     .ant-btn-disabled:hover, .ant-btn.disabled, 
     .ant-btn.disabled.active, .ant-btn.disabled:active, 
     .ant-btn.disabled:focus, .ant-btn.disabled:hover, 
     .ant-btn[disabled], 
     .ant-btn[disabled].active, .ant-btn[disabled]:active, 
     .ant-btn[disabled]:focus, .ant-btn[disabled]:hover {
        background: $Background_Header2;
        color: $color_white;
    }

}

.box-data-insert-mat {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    margin-bottom: 15px;

    .title-box {
        font-weight: bold;
        font-size: 15px;
        padding-right: 10px;
        margin-bottom: 10px;
        width: 20%;
        text-align: right;
    }

    .matters-container, .date-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        .matter-detail{
            width: 100%;
            border-bottom: 1px solid black;
            margin-bottom: 20px;
            padding-bottom: 10px;
            display: flex;
            .matter-name {
                width: 80%;
                margin-right: 10px;
            }
        }
        .detail{
            width: 100%;
            margin-bottom: 5px;
            padding-bottom: 5px;
            display: flex;
        }
    }
}

.box-data-insert-date {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title-box {
        font-weight: bold;
        font-size: 15px;
        padding-right: 10px;
        margin-bottom: 10px;
        width: 20%;
        text-align: right;
    }
}

.box-data-insert-autocert {
    display: flex;
    flex-direction: column;
    width: 100%;
    .autCert-title {
        padding-top: 20px;
        font-weight: bold;
    }
    .autCert-message {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}